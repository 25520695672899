<script>
import api from '../../../api';
// import NumberInputSpinner from "vue-number-input-spinner";
import DatePicker from "vue2-datepicker";
import Swal from 'sweetalert2';
// import Swal from 'sweetalert2'
/**
 * Dashboard component
 */
export default {
  components: {
    // NumberInputSpinner,
    DatePicker
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Zawiyah "
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      questions: [],
      answers: [],
      selectedDate: null,
      language: 'english',
      showMenu: false,
      // level: 2,
      loading: false,
      requestedLevelChange: false,
      userData: JSON.parse(localStorage.getItem('user_info')),
    };
  },
  methods: {
    async requestLevelChange(level) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Confirm!"
      }).then(async result => {
            if (result.value) {
              this.requestedLevelChange = true;
                try {
                  await api.patch(`/users/${this.userData.id}`, {
                  "requestedLevel": level,
                  "pendingLevelRequest": true
                })
          } catch (e) {
            console.log(e)
          }
        }
      });
    },
    async getQuestions() {
      const {data} = await api.get('/questions?filter[order]=order ASC');
      this.questions = data.filter(x => x.level == this.userData.level)
    },
    async getAnswers() {
      this.answers = (await api.get(`/users/${this.userData.id}/answers`)).data;
      this.answers = this.answers.filter(x => new Date(x.date).toDateString() == new Date(this.selectedDate).toDateString())
      // for(let question of this.questions) {
      //   if(question.type == 'multiple') {
      //     let answerFound = this.answers.filter(x => x.questionsId == question.id)[0]
      //     if(!answerFound) {
      //       this.answers.push({
      //         questionsId: question.id,
      //         value: 0
      //       })
      //     }
      //   }     
      // }
    },
    async getUserInfo(){
      const response = (await api.get(`/users/${this.userData.id}`)).data;
      if(response){
        this.userData = response;
      }
    },
    async updateNumber(question, event) {
      let newAnswer;
      let prevAnswer = this.answers.filter(x => x.questionsId == question.id)[0]
      newAnswer = event.target.value
      this.loading = true;
        if(prevAnswer) {
          try {
            let where = {
              id: prevAnswer.id
            }
            where = encodeURIComponent(JSON.stringify(where))
            await api.patch(`/users/${this.userData.id}/answers?where=${where}`, {
              "value": newAnswer,
              "date": new Date(this.selectedDate).toISOString(),
              "userId": this.userData.id,
              "questionsId": question.id
            })
            this.getAnswers()
            // this.answers.push({
            //   "value": newAnswer,
            //   "date": new Date(this.selectedDate).toISOString(),
            //   "userId": this.userData.id,
            //   "questionsId": question.id
            // })
            // Swal.fire("Answers Updated Successfully!", 'data.id', "success");
          } catch(err) {
            console.log(err)
          }  
        } else {
          // newAnswer = true
          try {
            await api.post(`/users/${this.userData.id}/answers`, {
              "value": newAnswer,
              "date": new Date(this.selectedDate).toISOString(),
              "userId": this.userData.id,
              "questionsId": question.id
            })
            this.getAnswers()
            // Swal.fire("Answers Updated Successfully!", 'data.id', "success");
          } catch(err) {
            console.log(err)
          }  
        }
        this.loading = false;
    },
    async updateCheckbox(question) {
      let newAnswer;
      let prevAnswer = this.answers.filter(x => x.questionsId == question.id)[0]
        if(prevAnswer) {
          newAnswer = !prevAnswer.value
          try {
            let where = {
              id: prevAnswer.id
            }
            where = encodeURIComponent(JSON.stringify(where))
            await api.patch(`/users/${this.userData.id}/answers?where=${where}`, {
              "value": newAnswer,
              "date": new Date(this.selectedDate).toISOString(),
              "userId": this.userData.id,
              "questionsId": question.id
            })
            this.getAnswers()
            // Swal.fire("Answers Updated Successfully!", 'data.id', "success");
          } catch(err) {
            console.log(err)
          }  
        } else {
          newAnswer = true
          try {
            await api.post(`/users/${this.userData.id}/answers`, {
              "value": newAnswer,
              "date": new Date(this.selectedDate).toISOString(),
              "userId": this.userData.id,
              "questionsId": question.id
            })
            this.getAnswers()
            // Swal.fire("Answers Updated Successfully!", 'data.id', "success");
          } catch(err) {
            console.log(err)
          }  
        }
    }
  },
  watch: {
  //   answers: {
  //     async handler(val) {
  //       console.log(val)
  //       for(let answer of val) {
  //         console.log(typeof(answer.value))
  //         if(typeof(answer.value) == 'number') {
  //           if(answer.id) {
  //             try {
  //               let where = {
  //                 questionsId: answer.questionsId
  //               }
  //               console.log(where)
  //               where = encodeURIComponent(JSON.stringify(where))
  //               await api.patch(`/users/${this.userData.id}/answers?where=${where}`, {
  //                 "value": answer.value,
  //                 "date": new Date(),
  //                 "userId": this.userData.id,
  //                 "questionsId": answer.questionsId
  //               })
  //               // Swal.fire("Answers Updated Successfully!", 'data.id', "success");
  //             } catch(err) {
  //               console.log(err)
  //             }  
  //           } else {
  //             try {
  //               await api.post(`/users/${this.userData.id}/answers`, {
  //                 "value":answer.value,
  //                 "date": new Date(),
  //                 "userId": this.userData.id,
  //                 "questionsId": answer.questionsId
  //               })
  //               // Swal.fire("Answers Updated Successfully!", 'data.id', "success");
  //             } catch(err) {
  //               console.log(err)
  //             }  
  //           }
  //           // console.log(answer.value)
  //           // let answerFound = this.answers.filter(x => x.questionsId == question.id)[0]
  //           // if(!answerFound) {
  //           //   this.answers.push({
  //           //     questionsId: question.id,
  //           //     value: 0
  //           //   })
  //           // }
  //         }     
  //       }      
  //     },
  //     deep: true
  //   },
    selectedDate() {
      this.answers = []
      this.getAnswers()
      // console.log('ehllo')
    }
  },
  computed: {
    totalScore() {
      let result = 0;
      for(let question of this.questions) {
        if(question.type == 'multiple') {
          result += question.ticks * 5
        } else {
          // if(answer.value) {
          result +=  5
          // }
        }
      }
      return result
    },
    score() {
      let result = 0;
      for(let answer of this.answers) {
        if(typeof(answer.value) == 'number') {
          result += answer.value * 5
        } else {
          if(answer.value) {
          result +=  5
          }
        }
      }
      return result
    },
    // userData() {
    //   return JSON.parse(localStorage.getItem('user_info'))
    // },
  },
  mounted() {
    this.selectedDate = new Date()
    this.getQuestions()
    this.getAnswers()
    this.getUserInfo()
  }

}
</script>

<template>
    <div v-if="userData && userData.id">
      <!-- <div class="mb-4">
        <label for="">Select Date</label>
        <b-form-input class="col-2" id="date" v-model="selectedDate" type="date"></b-form-input>
      </div> -->
      <div class="row container">
        <div class="col-4 mb-3">
          <label>Select Date</label>
          <br />
          <date-picker class="m-0 p-0"
          :disabled-date="(date) => date >= new Date()" v-model="selectedDate"
          :clearable="false"
          :first-day-of-week="1"
          :placeholder="selectedDate ? selectedDate.toISOString().substring(0,10) : ''"
          lang="en"></date-picker>
        </div>
        <div class="col-4 mb-3">
          <label>Select Language</label>
          <br />
          <select class="form-control" name="language" id="language" v-model="language">
            <option value="english">English</option>
            <option value="urdu">Urdu</option>
          </select>
        </div>
      </div>

    <div v-if="selectedDate" class="row" v-show="questions.length">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">

            <h4 class="card-title mb-4">Report - Score {{score}} / {{totalScore}}</h4>
            
            <div class="row mt-4">
              <div :dir="language == 'english' ? 'ltr' : 'rtl'" v-for="question in questions" :key="question.id" class="col-12 row">
                <p class="col-8">{{question[language]}}</p>
                <p class="col-4">
                  <!-- {{answers.length && answers.filter(x => x.questionsId == question.id)[0] ? answers.filter(x => x.questionsId == question.id)[0].value : ''}} -->
                  <!-- <input v-if="question.type == 'single'" type="checkbox"
                  :checked="answers.length && answers.filter(x => x.questionsId == question.id)[0] ? answers.filter(x => x.questionsId == question.id)[0].value : false"
                  style="width: 18px; height: 18px"/> -->
                  <input v-if="question.type == 'single'" type="checkbox"
                  :value="answers.length && answers.filter(x => x.questionsId == question.id)[0] ? answers.filter(x => x.questionsId == question.id)[0].value : false"
                  :checked="answers.length && answers.filter(x => x.questionsId == question.id)[0] ? answers.filter(x => x.questionsId == question.id)[0].value : false"
                  @change="updateCheckbox(question)"
                  style="width: 18px; height: 18px"/>
                  <!-- <span v-if="question.type == 'multiple'">
                    <NumberInputSpinner :min="0" v-if="answers.length && answers.filter(x => x.questionsId == question.id)[0]" :max="question.ticks" :integerOnly="true"
                    v-model="answers.filter(x => x.questionsId == question.id)[0].value"/>   -->
                    <!-- <NumberInputSpinner v-else :min="0" :max="question.ticks" :integerOnly="true"/> -->
                  <!-- </span> -->
                  <!-- {{answers}} -->
                  <input v-if="question.type == 'multiple'" type="number" :min="0" :max="question.ticks" :disabled="loading"
                  :value="answers.length && answers.filter(x => x.questionsId == question.id)[0] ? answers.filter(x => x.questionsId == question.id)[0].value : false"
                  @click="updateNumber(question, $event)"
                  >
                  <!-- <NumberInputSpinner v-if="question.type == 'multiple'" :min="0" :max="question.ticks" :integerOnly="true"
                  v-model="answers.filter(x => x.questionsId == question.id)[0].value"/> -->
                </p>
              </div>
              <!-- <div class="container">
                <div class="row">
                    <div class="col-md-12 text-right">
                      <button class="btn btn-success pull-right">Update</button>
                    </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>      
      </div>
    </div>
    </div>
</template>