<script>
import api from '../../../api';
import DatePicker from "vue2-datepicker";
import {formatDate} from "../../../helpers/general";

export default {
  components: {
    // Statistics,
    DatePicker
    // NumberInputSpinner
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('user_info')),
      distributedSeries: {
        data: {
            labels: [],
            series: []
        },
        options: {
            height: 300,
            distributeSeries: true,
        }
      },
      daterange: [],
      answers: [],
    };
  },
  methods: {
    async getAllAnswers() {
      let user_id = this.userData?.id
      this.answers = (await api.get(`/users/${user_id}/answers`)).data;
    },
    async getStatistics() {
      if(this.userData && this.dateRange !== []){
        await this.getAllAnswers()
        let start_date = new Date(this.daterange[0])
        start_date.setDate(start_date.getDate() + 1);        
        let end_date = new Date(this.daterange[1])
        end_date.setDate(end_date.getDate() + 1);

        this.answers = this.answers.filter(
          x => 
          new Date(start_date).getTime() <= new Date(x.date).getTime() &&
          new Date(end_date).getTime() >= new Date(x.date).getTime()
        )
        const groups = this.answers.reduce((groups, answer) => {
          const date = answer.date;
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(answer);
          return groups;
        }, {});

        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date,
            answers: groups[date]
          };
        });

        let labels = []
        let series = []

        for(let group of groupArrays) {
          let result = 0;

          for(let answer of group.answers) {
            if(typeof(answer.value) == 'number') {
              result += answer.value * 5
            } else {
              if(answer.value) {
              result +=  5
              }
            }
          }
          let formattedDate = formatDate(group.date)
          labels.push(formattedDate)
          series.push(result)
        }
        labels.reverse()
        series.reverse()
        this.distributedSeries.data.labels = labels
        this.distributedSeries.data.series = series
      }
    },
  },
  watch: {
    daterange: {
      handler() {
        // this.expandStatistics = null;
        this.getStatistics()
      },
      deep: true
    }
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group mb-3">
                        <label>Date Range</label>
                        <br />
                        <div class="col-4 mb-3">
                        <date-picker class="m-0 p-0" v-model="daterange" range append-to-body lang="en" confirm></date-picker>
                        </div>
                      </div>
                      <!-- Distributed series -->
                      <chartist
                        ratio="ct-chart"
                        :data="distributedSeries.data"
                        :options="distributedSeries.options"
                        type="Bar"
                      ></chartist>
        
    </div>
  </div>
</template>
