<script>
import api from '../../../api';
import Layout from "../../layouts/main";
import Dashboard from "./dashboard.vue";
import Stats from "./statistics.vue"
// import NumberInputSpinner from "vue-number-input-spinner";
import Swal from 'sweetalert2';
// import Swal from 'sweetalert2'
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    Dashboard,
    Stats,
    // NumberInputSpinner,
  },
  data() {
    return {
      title: "Dashboard",
      tabs: [{name: "Dashboard", active: false}, {name: "Analysis", active:false}], //
      items: [
        {
          text: "Zawiyah "
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      language: 'english',
      showMenu: false,
      // level: 2,
      loading: false,
      requestedLevelChange: false,
      userData: JSON.parse(localStorage.getItem('user_info')),
    };
  },
  methods: {
    async requestLevelChange(level) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Confirm!"
      }).then(async result => {
            if (result.value) {
              this.requestedLevelChange = true;
                try {
                  await api.patch(`/users/${this.userData.id}`, {
                  "requestedLevel": level,
                  "pendingLevelRequest": true
                })
          } catch (e) {
            console.log(e)
          }
        }
      });
    },
    async getUserInfo(){
      const response = (await api.get(`/users/${this.userData.id}`)).data;
      if(response){
        this.userData = response;
      }
    },
    async changeTab(tab){
      this.tabs = this.tabs.map(t => t.name == tab.name ? {name: tab.name, active: !tab.active} : {name: t.name, active: false});
    }
  },
  watch: {
  },
  computed: {
    totalScore() {
      let result = 0;
      for(let question of this.questions) {
        if(question.type == 'multiple') {
          result += question.ticks * 5
        } else {
          // if(answer.value) {
          result +=  5
          // }
        }
      }
      return result
    },
    score() {
      let result = 0;
      for(let answer of this.answers) {
        if(typeof(answer.value) == 'number') {
          result += answer.value * 5
        } else {
          if(answer.value) {
          result +=  5
          }
        }
      }
      return result
    },
    // userData() {
    //   return JSON.parse(localStorage.getItem('user_info'))
    // },
  },
  mounted() {
    this.selectedDate = new Date()
    this.tabs = [{name: "Dashboard", active: true}, {name: "Analysis", active:false}]
    this.getQuestions()
    this.getAnswers()
    this.getUserInfo()
  }

}
</script>

<template>
  <Layout>
    <div v-if="userData && userData.id">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <!-- <h4 class="mb-0">{{ title }}</h4> -->
          <ul class="nav nav-tabs">
            <li class="nav-item" v-for="tab in tabs" :key="tab.name">
              <div :class="{'nav-link':true, active: tab.active}" role="button" @click="changeTab(tab)"><h4 class="mb-0">{{tab.name}}</h4></div>
            </li>
          </ul>
          <div class="page-title-right">
            <button v-if="userData.level == 2 || userData.level == 3" class="btn btn-primary pull-right mr-2" @click="requestLevelChange(parseInt(userData.level) - 1)"
            :disabled="userData.pendingLevelRequest ? userData.pendingLevelRequest : requestedLevelChange"
            >Downgrade to Level {{parseInt(userData.level) - 1}}</button>
            <button v-if="userData.level == 1 || userData.level == 2" class="btn btn-primary pull-right" @click="requestLevelChange(parseInt(userData.level) + 1)"
            :disabled="userData.pendingLevelRequest ? userData.pendingLevelRequest : requestedLevelChange"
            >Upgrade to Level {{parseInt(userData.level) + 1}}</button>
          </div>
        </div>
      </div>
    </div>
    <div v-for="tab in tabs" :key="tab.name">
      <Dashboard v-if="tab.name == 'Dashboard' && tab.active === true" />
      <Stats v-if="tab.name == 'Analysis' && tab.active === true" />
    </div>
    
    </div>
  </Layout>
</template>